<template>
    <div class="gokhale-cal">
        <div class="gokhale-cal__loading" v-if="loading">
            <img :src="url + '/svg/loading.svg'" class="gokhale-cal__loading-icon" alt="Loading">
        </div>
        <div class="row gokhale-cal__inputs-row" v-else>
            <div :class="{'col-sm-6': !hasNumberOfPersons, 'col-sm-4': hasNumberOfPersons }" v-if="hasMultipleLessons">
                <select-lesson></select-lesson>
            </div>
            <div :class="{'col-sm-6': !hasNumberOfPersons, 'col-sm-4': hasNumberOfPersons }" v-else>
                <div class="form-group gokhale-cal__inputs">
                    <label class="gokhale-cal__fake-label">&nbsp;</label>
                    <div class="gokhale-cal__fake-input">{{ lessonName }}</div>
                </div>
            </div>
            <div class="gokhale-cal__inputs col-sm-4" v-if="hasNumberOfPersons">
                <select-number-of-persons></select-number-of-persons>
            </div>
            <div :class="{'col-sm-6': !hasNumberOfPersons, 'col-sm-4': hasNumberOfPersons }" v-if="hasLocations">
                <select-location></select-location>
            </div>
            <div :class="{'col-sm-6': !hasNumberOfPersons, 'col-sm-4': hasNumberOfPersons }" v-else>
                <div class="form-group gokhale-cal__inputs">
                    <label class="gokhale-cal__fake-label">&nbsp;</label>
                    <div class="gokhale-cal__fake-input">{{ locationName }}</div>
                </div>
            </div>
        </div>
        <div class="flex-row" v-if="showCalendar">
            <div class="planyo-calendar-and-list">
                <event-calendar></event-calendar>
                <event-list></event-list>
            </div>
            <div class="event-detail-container">
                <event-detail-container></event-detail-container>
            </div>
        </div>
    </div>
</template>

<script>
const Vue = require('vue');
import SelectLesson from './SelectLesson.vue';
import SelectLocation from './SelectLocation.vue';
import SelectNumberOfPersons from './SelectNumberOfPersons.vue';
import EventCalendar from '../EventCalendar.vue';
import EventList from '../EventList.vue';
import EventDetailContainer from '../EventDetailContainer.vue';
import getUrl from '../../modules/getUrl.js';

export default {
    data() {
        return {
            loading: true,
        };
    },
    props: {
        teacher: {
            default: false,
            required: true,
        },
        user: {
            default: -1,
        },
        hadInitialConsultation: {
            default: false,
        },
        isAlumni: {
            default: false,
        },
        resources: {
            default: () => {},
            required: false,
        }
    },
    components: {
        SelectLesson,
        SelectLocation,
        SelectNumberOfPersons,
        EventCalendar,
        EventList,
        EventDetailContainer,
    },
    created() {
        this.$store.hadInitialConsultation = this.hadInitialConsultation;
        this.$store.isAlumni = this.isAlumni;
        this.$store.teacher = this.teacher;
        this.$store.passedInResources = this.resources;
        this.$store.calendar.setTeacher(this.teacher);
        this.$store.calendar.setLanguage(this.language);
        this.$store.calendar.setFilter(this.filter);

        // If the user ID is passed, call the API to determine if they are an alumni or have had initial consultation
        if (this.user !== -1) {
            axios.post(getUrl() + 'api/user-status', {id: this.user})
                .then(response => {
                    this.$store.isAlumni = response.data.isAlumni;
                    this.$store.hadInitialConsultation = response.data.hadInitialConsultation;
                    this.getLessons();
                })
                .catch(error => {
                    this.$store.isAlumni = false;
                    this.$store.hadInitialConsultation = false;
                    this.getLessons();
                    console.error("resources/js/components/AvailabilityCalendar/AvailabilityCalendar.vue@created() > user status", error);
                });
        } else {
            this.getLessons();
        }

        this.$store.calendar.update();

        // Collect the list of resources for this teacher
        // This is used on the event detail screen to get resource specific information
        axios.post(getUrl() + 'api/resources', {teacher: this.teacher})
            .then(response => {
                this.$store.resources = {};
                this.$store.foundationsCourse = false;
                response.data.forEach(resource => {
                    this.$store.resources[resource.planyo_id] = resource;
                    if (resource.prop_lesson_type == 'Private Foundations Course') {
                        this.$store.foundationsCourse = resource.planyo_id;
                    }
                });
            })
            .catch(error => {
                console.error('resources/js/components/AvailabilityCalendar/AvailabilityCalendar.vue@created() > collect resources', error);
            });

    },
    computed: {
        url() {
            return getUrl()
        },
        showCalendar() {
            if (this.$store.calendar.lesson == '') {
                return false;
            }
            return true;
        },
        hasMultipleLessons() {
            if (this.$store.lessons.length > 1) {
                return true;
            }
            return false;
        },
        lessonName() {
            return this.$store.lessons[0];
        },
        hasLocations() {
            if (this.$store.calendar.locations.length > 1) {
                return true;
            }
            return false;
        },
        locationName() {
            if (this.$store.calendar.locations.length == 0) {
                return '';
            }
            if (this.$store.calendar.locations[0].prop_location == '') {
                return '';
            }
            if (this.$store.calendar.locations[0].prop_location == 'Online') {
                return 'Online';
            }
            return 'In ' + this.$store.calendar.locations[0].prop_location;
        },
        hasNumberOfPersons() {
            if (typeof this.$store.calendar.lesson == 'undefined') {
                return false;
            }
            return (this.$store.calendar.lesson == 'Private Foundations Course' || this.$store.calendar.lesson == 'Single Lesson' || this.$store.calendar.lesson == 'Private Follow-up') ? true : false;
        }
    },
    methods: {
        getLessons() {
            this.loading = true;
            this.$store.lessons = [];
            axios.post(getUrl() + 'api/lessons', {
                lang: this.$store.language,
                teacher: this.$store.teacher,
                hadInitialConsultation: this.$store.hadInitialConsultation,
                isAlumni: this.$store.isAlumni,
                passedInResources: this.$store.passedInResources,
                user: this.$store.user,
            })
            .then(response => {
                this.$store.lessons = [];
                let lessons = [];
                for (let index = 0, len = response.data.length; index < len; index++) {
                    if (response.data[index].prop_lesson_type) {
                        lessons.push(response.data[index].prop_lesson_type);
                    }
                }
                Vue.set(this.$store, 'lessons', lessons);
                this.$store.calendar.lesson = lessons[0];
                this.loading = false;
            })
            .catch(err => {
                console.error('resources/js/components/AvailabilityCalendar/AvailabilityCalendar.vue@getLessons()', err);
            });
        },
    },
}
</script>
