<template>
    <div class="planyo-event-list">
        <h2>Available Times</h2>
        <div v-bar class="scroll-area">
            <div>
                <div class="no-events" v-if="noEvents" v-html="reasonForNoEvents"></div>
                <div v-else v-for="event in todaysEvents" :key="event.id" :class="{ 'event':true , 'event-selected': (event.id == selectedEvent.id) }" @click="selectEvent(event)" :id="'event-' + event.id">
                    <h3>{{ startTime(event) }}</h3>
                </div>
            </div>
            <template v-if="link !== false">
                <template v-if="!isPrivateFoundationLesson">
                    <a :href="link" class="btn btn-primary event-list-button event-list-hide">Book Lesson</a>
                </template>
                <template v-else>
                    <a :href="link" class="btn btn-primary event-list-button event-list-hide">Purchase Course</a>

                    <span class="private-foundation-course-info event-list-hide">* Please note this step does not create a booking for your first lesson. After purchasing the course, you will be prompted to book your first lesson from your dashboard.</span>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        store: {
            resources: 'resources',
            selectedTeacherLocation: 'selectedTeacherLocation',
            todaysEvents() {
                return 'calendar.todaysEvents';
            },
            selectedEvent() {
                return 'calendar.selectedEvent';
            },
        },
        watch: {
            selectedEvent: function(newVal, oldVal) {
                this.$store.selectedTeacherLocation = newVal.planyo_id;
            },
        },
        computed: {
            isPrivateFoundationLesson() {
                if (typeof this.$store.calendar.lesson == 'undefined') {
                    return false;
                }
                return (this.$store.calendar.lesson == 'Private Foundations Course') ? true : false;
            },
            startTimeMoment() {
                if (this.selectedEvent && this.selectedEvent.start_time) {
                    return moment(this.selectedEvent.start_time.date);
                }
                return moment();
            },
            teacherName() {
                if (typeof this.resources[this.selectedTeacherLocation] !== 'undefined') {
                    return this.resources[this.selectedTeacherLocation].teacher_name;
                }
                return '-';
            },
            link() {
                if (this.$store.selectedTeacherLocation && this.selectedEvent && this.selectedEvent.planyo_id) {
                    let timeAsDecimal = 0;
                    timeAsDecimal = this.startTimeMoment.hours() + (this.startTimeMoment.minutes() / 60);
                    if (this.isPrivateFoundationLesson) {
                        // return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id='+this.foundationsCourse+'&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&rental_prop_Teacher_ID=' + this.$store.teacher;
                        return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id='+encodeURIComponent(this.$store.selectedTeacherLocation)+'&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName) + '&rental_prop_Teacher_ID=' + encodeURIComponent(this.$store.selectedTeacherLocation);
                    }
                    if (this.hasNumberOfPersons) {
                        return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id=' + encodeURIComponent(this.$store.selectedTeacherLocation) + '&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName) + '&rental_prop_Teacher_ID=' + encodeURIComponent(this.$store.selectedTeacherLocation);
                    }
                    return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id=' + encodeURIComponent(this.$store.selectedTeacherLocation) + '&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName) + '&rental_prop_Teacher_ID=' + encodeURIComponent(this.$store.selectedTeacherLocation);
                }
                return false;
            },
            reasonForNoEvents() {
                if (this.$store.calendar.lesson == '' || this.$store.calendar.location == '') {
                    if (this.$store.calendar.lesson == '' && this.$store.calendar.location == '') {
                        return 'Please select a lesson and location to view upcoming availabilty';
                    } else if (this.$store.calendar.lesson == '') {
                        return 'Please select a lesson to view upcoming availabilty';
                    }
                    return 'Please select a location to view upcoming availabilty';
                }
                if (this.$store.calendar.selectedDay === false && this.$store.calendar.todaysEvents.length == 0) {
                    return 'Please select a day of the month to view upcoming availability';
                }
                if (this.haveEventsThisMonth && this.$store.calendar.todaysEvents.length == 0) {
                    return 'There are no lessons scheduled on this day, please select a different day';
                }
                if (!this.haveEventsThisMonth) {
                    return 'There are no lessons scheduled this month, please check other months';
                }
                return '';
            },
            haveEventsThisMonth() {
                return (this.$store.calendar.events.length > 0) ? true : false;
            },
            noEvents() {
                if (this.haveEventsThisMonth) {
                    if (this.$store.calendar.todaysEvents.length == 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            },
        },
        methods: {
            startTime(event) {
                let time = moment(event.start_time.date);
                return time.format("h:mm a");
            },
            selectEvent(selectedEvent) {
                this.$store.calendar.selectEvent(selectedEvent);
                this.$store.selectedTeacherLocation = selectedEvent.planyo_id;
            }
        },
    }
</script>
